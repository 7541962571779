$white: #FFFFFF;
$black: #000000;
$gray: #C8CFDA;
$gray-alt: #CBD4D3;
$gray-light: #F7F7F7;
$gray-opacity: rgba(217, 217, 217, 0.2);
$navy: #012D25;
$light-blue: #DFE5ED;
$file-icon: #E8FAF8;
$table-hover: #eeeeee;
//$action: #0E7560; // Initial green it was on
// Actionstep green #0D846C
$action: #0D846C;

// Override Bootstrap's default success color
$success: #0D846C;
$green: #0D846C;  // Bootstrap also uses this variable
// --bs-success: #0D846C;  // CSS variable override

$table-hover-bg: $table-hover !important;
$dropdown-link-hover-bg: $action !important;

$custom-colors: (
  "action": $action,
  "white": $white,
  "success": $success,
);

$theme-colors: map-merge($theme-colors, $custom-colors);